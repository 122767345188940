/* Mobile Styles */
.valueCard {
  width: 310px;
  border-radius: 50px;
  background: linear-gradient(116.12deg, #c2e0fb 0%, #cbf9f3 100%);
  padding: 55px 37px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 5px;
  
}

.valueCard.rev {
  background: linear-gradient(116.12deg, #cbf9f3 0%, #c2e0fb 100%);
}

.valueCard h2 {
  font-family: 'Karla';
  font-size: 24px;
  font-weight: 700px;
  text-align: start;
  line-height: 28.06px;
  margin: 0;
}

.valueCardTextSub {
  font-family: 'Rubik';
  font-size: 18px;
  width: 236px;
  line-height: 21.33px;
  text-align: start;
}

.valueCard {
  height: 400px;
  width: 310px;
}

/* Desktop Styles */
@media screen and (min-width: 1200px) {
  /* native css option (unused): https://stackoverflow.com/a/29223812 */

  .valueCard {
    width: 357px;
    height: 286px;
    background: None;
  }

  .valueCard.rev {
    background: None;
    /* border: 1px purple dashed; */
  }

  .valueCard h2 {
    font-size: 34px;
    line-height: 39px;
    font-weight: 700px;
    text-align: start;
    width: 181px;
  }

  .valueCardTextSub {
    text-align: start;
  }

  .cardContentWrapper {
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardFront {
    position: absolute;
    height: inherit;
    width: inherit;
    background-image: url(../../components/assets/ValueCardBackgrounds/ValueCardBackground1.svg);

    transition: opacity 300ms ease-in;
  }

  .rev .cardFront {
    background-image: url(../../components/assets/ValueCardBackgrounds/ValueCardBackground2.svg);
  }

  .cardBack {
    position: absolute;
    height: inherit;
    width: inherit;
    background-image: url(../../components/assets/ValueCardBackgrounds/ValueCardBackgroundHover1.svg);
    opacity: 0;

    transition: opacity 300ms ease-in;
  }

  .rev .cardBack {
    background-image: url(../../components/assets/ValueCardBackgrounds/ValueCardBackgroundHover2.svg);
  }

  .valueCard:hover .cardFront {
    opacity: 0;
  }

  .valueCard:hover .cardBack {
    opacity: 1;
  }
}

#parentContainer {
  max-width: 1096px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  /* margin-bottom: 5%; */
  justify-content: center;
  display: flex;
  /* border: 1px dashed brown; */
}

#studentsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 50%;
  height: 375px;
  padding: 2rem ;
  justify-content: center;
  margin: auto 5% auto 5%;
  width: auto !important;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(to right, #C8E3FB, #D1FAF4); /* Example gradient */
}

#nonprofitsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 50%;
  height: 375px;
  padding: 2rem ;
  justify-content: center;
  margin: auto 5% auto 5%;
  width: auto !important;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(to right, #C8E3FB, #D1FAF4); /* Example gradient */

}

#parentContainer h1 {
  text-align: center;
}

#parentContainer p {
  text-align: start;
  /* border: 1px red dashed; */
  /* margin-bottom: 30px; */
}

@media (max-width: 1000px) {
  #parentContainer {
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  #parentContainer {
    position: relative;
    /* margin-left: 10%;
    margin-right: 10%; */
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 20%;
    margin-bottom: 5%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 4rem;
  }

  #studentsContainer {
    top: 150%;
    flex: 50%;
    justify-content: center;
    width: 400px !important;
    text-align: center;
    padding-bottom: 10%;
  }

  #nonprofitsContainer {
    flex: 50%;
    justify-content: center;
    /* margin: auto; */
    width: 400px !important;
    text-align: center;
    padding-bottom: 10%;
  }

  #parentContainer .bluebtn {
    margin-top: 5%;
  }
}

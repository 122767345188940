/* adjusting min-height so footer stays at the bottom of the page when zoomed out */
.nonprofitApply {
  min-height: calc(100vh - 815px);
}

.nonprofitApplyHeader p {
  text-align: center;
  margin: 0;

  padding: 0 11%;
}

.nonprofitApplyHeader h1 {
  text-align: center;
  margin: 0;
}

.nonprofitApplyHeader h2 {
  text-align: center;
  margin: 0;

  padding: 0 11%;
}

.howToHead {
  color: #036aca;
  text-align: center;
}

.howToCont {
  margin-top: -12rem;
  padding: 0 6rem;
  width: 100%;
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 700px) {

  .subHead {
    font-size: 20px;
    width: 100%;
    padding: 0;
  }
}


.subHead {
  width: 80%;
}



.nonprofitApplyHeader {
  background-image: url(../../components/assets/backgrounds/apply_header.svg);
  background-size: cover;
  background-position: bottom;
  min-width: 100%;
  min-height: 810px;
}

.nonprofitApplyHeaderContent {
  padding-top: clamp(120px, 18vw, 224px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
}

.nonprofitApplyHeaderContent p {
  text-align: center;
}

.howToApply h2 {
  text-align: center;
  /* margin: 56px 0 42px; */
  /* border: 1px dashed red; */
}

.howToApply p {
  text-align: center;
  /* padding: 0 11%; */
  /* margin: 0; */
  /* border: 1px dashed red; */
}

.howToApply {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  /* border: 1px dashed blue; */
  margin-top: -400px;
}

.orgLogo {
  height: 80px;
  width: 80px;
}

.applyButton {
  margin-top: 65px;
  text-align: center;
}

.faq {
  margin-top: 96px;
}

.footerDiv {
  margin-top: -50px;
}

@media screen and (min-width: 700px) {
  .carousel {
    margin-top: -200px;
  }

  .orgLogo {
    height: 200px;
    width: 200px;
  }

}

@media screen and (min-width: 1200px) {
  .nonprofitApplyHeader {
    background-image: url(../../components/assets/backgrounds/apply_header_desktop.svg);
    min-height: 989px;
  }

  .howToCont {
    padding: 0 12rem;
    gap: 2rem; 
    margin-top: -14rem;
  }
  .subHead {
    width: 55%;
  }



  .nonprofitApplyHeader p {
    padding: 0 21%;
  }

  .carousel {
    margin-top: -250px;
  }

  .howToApply h2 {
    margin: 145px 0 40px;
  }

  .howToApply p {
    padding: 0 21%;
  }

  .faq {
    margin-top: 110px;
  }

  .timelineStepText h2,
  p {
    text-align: left;
  }

  .footerDiv {
    margin-top: -420px;
  }

}

/* Desktop Styles */
/* native css option (unused): https://stackoverflow.com/a/29223812 */

.roleCardTextMain h2 {
  color: #000000;
  font-size: 34px;
}

.roleCard {
  width: 275px;
  height: 400px;
  /* remove mobile background  */
  background: none;
}

.roleCard.rev {
  background: none;
}

.cardContent {
  align-items: center;
  height: 400px;
  width: 275px;
  text-align: center;
}

.cardContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.cardFront {
  position: absolute;
  height: inherit;
  width: inherit;
  transition: opacity 300ms ease-in;
}

.cardFront .cardContentWrapper span {
  font-size: 34px;
  line-height: 40px;
}

.cardBack {
  position: absolute;
  height: inherit;
  width: inherit;
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.cardBack .cardContentWrapper span {
  font-size: 20px;
  line-height: 19px;
}

.header {
  flex-direction: column;
  gap: 7px;

  opacity: 1;
  transition: opacity 300ms ease-in;
}

/* hover styles */
.roleCard:hover .cardFront {
  opacity: 0;
}

.roleCard:hover .cardBack {
  opacity: 1;
}

/* Mobile Styles */
@media screen and (max-width: 800px) {
  .roleCard {
    height: 350px;
    width: 310px;
    border-radius: 50px;
    background: linear-gradient(116.12deg, #c2e0fb 0%, #cbf9f3 100%);
  }

  .cardContent {
    align-items: center;
    height: 355px;
    width: 310px;
  }

  .roleCard.rev {
    background: linear-gradient(116.12deg, #cbf9f3 0%, #c2e0fb 100%);
  }

  .cardBack {
    background-image: none !important;
  }

  .cardBack .cardContentWrapper span {
    font-size: 17px;
    line-height: 16px;
  }

  .cardContent {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .roleCard:hover .cardFront {
    opacity: 0;
  }
  
  .roleCard:hover .cardBack {
    opacity: 1;
  }
}

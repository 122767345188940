/* Mobile First Styling */

.aboutUs h1 {
  margin: 0;
  text-align: center;
}

.aboutUs h2 {
  margin: 0;
}

.navBarDiv {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 70px;
}

.headerDiv {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
}

.headerImg {
  width: 100%;
  height: auto;
}

.headerText {
  text-align: center;
  margin-top: -55%;
  width: 100%;
}

.ourMission {
  text-align: start;
  padding: 0px 10%;
  width: auto;
  margin-top: 10%;

  display: flex;
  flex-direction: column;
  gap: 30px;
  /* border: 1px blue dashed; */
}


.divider {
  height: 1px;
  background-color: black;
  width: 100%;
}

.divider1 {
  height: 1px;
  background-color: black;
  width: 50%;
}

.ourMission p {
  text-align: start;
}

.ourMission a {
  color:#333333;
}

.ourMission a:hover {
  color:#0069CA;
}

.valuesCardsDiv {
  padding: 53px 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 39px;
  /* border: 1px indianred dashed; */
}

.h2Cont {
  /* border: 1px red dashed; */
  width: 100%;
}

.h2Contain {
  width: 100%;
}

.h2Contain h2 {
  margin-bottom: 2rem;
}


.valuesCards {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 12px;
  /* border: 1px blue dashed; */
}

.execBoardDiv {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 63px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.execBoardPhotos,.teamMembersPhotos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 23px;
}

.teamMembersPhotos {
  margin-bottom: 30px;
}

.teamMembersDiv {
  margin-top: 45px;
  /* margin-bottom: 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
  padding-left: 2rem;
  padding-right: 2rem;
  /* border: 1px blue dashed; */
}

@media screen and (min-width: 1200px) {
  .valuesCardsDiv {
    padding: 82px 22%;
  }
  .h2Cont {
    margin-bottom: 2rem;
  }
  

  .valuesCards {
    flex-direction: row;
    gap: 80px;
    /* border: 1px purple dashed; */
  }

  .execBoardDiv {
    margin-top: 4rem;
    gap: 50px;
  }

  .h2Contain {
    padding: 0px 22%;
    /* border: 1px pink dashed; */
  }

  .h2Contain h2{
    text-align: start;
  }
  

  

  .execBoardPhotos, .teamMembersPhotos {
    padding: 0px 22%;
    justify-content: center;
    column-gap: 15px;
  }

  .teamMembersDiv {
    margin-top: 148px;
  }

  .teamMembersPhotos {
    z-index: 5;
  }

  .footerDiv {
    margin-top: -350px;
    z-index: 0;
  }
}

/* Should change with Navbar */
@media screen and (min-width: 700px) {
  .headerText {
    margin-top: -100px;
  }

  .ourMission {
    margin-top: 0px;
    padding: 0px 22%;
  }
}


#sectionTitle {
    text-align: center;
  }
  
  /* .featuredProjectCards {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  } */

.container {
  /* border: 1px dashed blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  /* border: 1px dashed purple; */
  color: #0069ca;
  text-decoration: underline;
  margin-bottom: 5rem;
}


  
  @media screen and (min-width: 1000px){
  }
  
   .featuredProjectCards {
    margin-left: 10%;
    margin-right: 10%;
  } 
  
  .seeMore {
    text-align: center;
  }
  
  @media screen and (min-width: 1200px) {
  
    .featuredProjectCards {
      max-width: 1096px;
      margin-left: auto;
      margin-right: auto;
    }
  
  }
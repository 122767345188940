.navBarDiv {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 70px;
  top: 0;
}

#OurWorkPage {
  justify-content: center;
}

/* ---------------------projects info container------------------------ */

.cont {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -2rem;
  /* border: 1px blue dashed; */
  /* margin-top: -16rem; */
}

.innerCont {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 350px;
  margin-top: 4rem;
  /* border: 1px red dashed; */

}

.imageCont {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: 1px pink dashed; */

}

.border {
  position: absolute;
  top: 0;
  width: auto;
  height: 100%;
}

.projImage {
  position: relative;
  width: 50%;
  height: auto;
}

.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px yellow dashed; */
  padding: 2rem;

}

.info h2 {
  font-style: italic;
}



/* --------------------------------------------- */

.container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* margin-top: -16rem; */
  margin-bottom: 2rem;
  border: 1px red dashed;
}

.currentProjects {
  margin-top: -100px;
  /* padding: 0 2%; */
  border: 1px green dashed;
}

.pastProjects {
  padding: 0 10%;
}

@media screen and (min-width: 1000px){
  .currentProjects {
    padding: 0 12%;
  }
}
/* Styling for mobile first */

.Footer {
  position: relative;
  background-image: url(../../components/assets/footer/footer_background_mobile.svg);
  /* sizing background image. Use mobile background for desktop too. */
  background-size: cover;
  min-width: 100%;
  min-height: 922px;
  z-index: 0;

  display: flex;
  justify-content: center;
}

.Footer p {
    margin: 0px;
}

.FooterContent {
  margin-top: 150px;
  margin-bottom: 100px;
  padding: 0px 5vh;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.FooterInfo {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.FooterInfo a {
  color: #f2594b;
  transition: color 300ms ease-out;
}

.FooterInfo a:hover {
  color: #0069ca;
}

.WordMark {
  width: 224px;
  height: auto;
}

.FooterIconsMobile {
  display: flex;
  gap: 32px;
}

.FooterIconsDesktop {
  display: none;
}

.SocialIcon img {
  width: 30px;
  height: 30px;
}

.SocialIcon g g rect {
  transition: fill 300ms ease-out;
}

.SocialIcon:hover g g rect {
  fill: #c2e0fb;
}

.FooterColumns {
  display: flex;
  flex-direction: column;
  gap: 5vw;
}

.FooterColumn {
  display: flex;
  flex-direction: column;
  gap: 18px;
  white-space: nowrap;
}

.FooterColumn a {
  color: #657788;
  text-decoration: none;
  transition: color 300ms ease-out;
}

.FooterColumn a:hover {
  color: #0069ca;
}


/* Desktop Styling */
@media screen and (min-width: 1000px) {
  .Footer {
    /* adjust height of background */
    min-height: 815px;
  }

  .FooterContent {
    margin-top: 400px;
    margin-bottom: 0px;
    gap: 100px;

    padding: 0;
    flex-direction: row;
  }

  .WordMark {
    width: 300px;
    height: auto;
  }

  .FooterColumns {
    flex-direction: row;
  }

  .FooterIconsDesktop {
    display: flex;
    gap: 32px;
  }

  .FooterIconsMobile {
    display: none;
  }

  .SocialIcon svg {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1200px) {
    .FooterContent {
        gap: 93px;
    }
}

.blueBG {
  z-index: -1;
  position: relative;
  left: 0%;
  right: 0%;
  margin-top: -5%;
  margin-bottom: 5%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.header {
  position: absolute;

  top: 5%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4rem;
  /* margin-left: 2.5%; */
  /* margin-bottom: 5%; */
  /* border: 1px dashed red; */
}

.text {
  /* border: 1px solid orange; */
}

.center {
  /* border: 1px solid orange; */
}


.featuredTitle {
  position: relative;
  color:#0069ca;
  /* identical to box height */
  text-align: center;
  text-decoration: underline;
}

.featuredDescription {
  position: relative;
  max-width: 1096px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3em;
}

.textOne {
  width: 550px;
  text-align: left;
  padding-bottom: 15px;
  /* add marigin top */
  margin-top: 1.58rem;
}

.hide {
  display: none;
}

.socialGoodSubText {
  text-align: left;
  color: #0069ca;
}

.textTwo {
  margin-bottom: 5%;
  width: 560px;
  text-align: left;
}

.graphic {
  width: 380.05px;
  height: 352.73px;
  /* margin-left: 5%; */
  /* border: 1px dashed blue; */
}

.mintbtn {
  margin: 10px 10px 10px 0px;
  display: flex;
  justify-content: left;
}

@media (max-width: 1400px) {

  .blueBG {
    width: 100vw;
    height: 110vh;
    min-height: 500px;
    object-fit: cover;
  }
}

@media (max-width: 1250px) {
  .top {
    top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .textOne {
    width: 50vw;
    left: 25%;
    text-align: center;
  }

  .textTwo {
    width: 50vw;
    left: 50%;
    text-align: center;
  }

  .mintbtn {
    margin: auto;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .graphic {
    display: none;
  }

  .featuredDescription {
    width: 80%;
  }
}

@media (max-width: 850px) {
  .top {
    width: auto;
  }

  .textOne {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
  }

  .socialGoodSubText {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
  }

  .blueBG {
    width: 100vw;
    height: 100vh auto;
  }


}

@media (max-height: 500px) {
  .top {
    display: flex;
    flex-direction: column;
  }
}

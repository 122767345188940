.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 49px;
}

.header {
  text-align: center;
  margin: 0px;
  width: 237px;
}

.faqRow {
  width: 100%;
}

.question {
  cursor: pointer;
  padding: 0 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position and z index ensures that invisible text will be behind the
   * questions */
  position: relative;
  z-index: 2;
}

.question h3 {
  font-family: Karla;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin: 0;
}

.question h3 {
  transition: color 300ms ease-out;
}

.question path {
  transition: fill 300ms ease-out;
}

/* If svgs are added in this div in the future; this selector may affect it */
.question:hover h3,
.question:hover path {
  color: #657788;
  /* arrow svg fill color */
  fill: #657788;
}

.answer p {
  font-size: 18px;
  line-height: 21.33px;
  text-align: left;
  margin: 0;
  padding: 22px 18% 0 20%;

  opacity: 0;
  max-height: 0;
  transition: max-height 300ms 0ms, opacity 300ms linear;
}

.answer.showing p {
  opacity: 1;
  max-height: 2000px;
  transition: max-height 0s 0s, opacity 300ms linear;
}

.arrow {
  transition: transform 0.25s;
}

.arrow.rotated {
  transform: rotate(0.25turn);
  transform-origin: center;
}

@media screen and (min-width: 1200px) {
  .header {
    width: 600px;
  }
}

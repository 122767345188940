.studentNonprofitSelector {
  display: flex;
  justify-content: center;
}

.mobile {
  display: flex;
  gap: 6.44px;
}

.desktop {
  display: none;
}

@media screen and (min-width: 1200px) {
  .desktop {
    display: flex;
    gap: 10px;
  }

  .mobile {
    display: none;
  }

  .greyDesktop path:first-child {
    fill: transparent;
    fill-opacity: 0;
    transition: fill 300ms ease-out, fill-opacity 300ms ease-out;
  }

  .greyDesktop:hover path:first-child {
    fill: #657788;
    fill-opacity: 0.3;
  }
}

#headerDiv {
    background-image: url(../../components/assets/backgrounds/apply_header.svg);
    background-size: cover;
    background-position: bottom;
    min-width: 100%;
    min-height: 810px;
    padding-left: 20px;
    padding-right: 20px;
}

.MessageSent {
    padding-top: clamp(120px, 18vw, 224px);
    text-align: center;
}

.body {
    margin-top: 60px;
}

.body p {
    text-align: center;
    font-weight: 700px;
    font-size: 34px;
    line-height: 1.1;
}
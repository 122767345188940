/* adjusting min-height of content container so footer stays at the bottom of the page when zoomed out */
.studentApply {
  min-height: calc(100vh - 815px);
}

/* Mobile Styles */
.studentApplyHeader h1 {
  text-align: center;
  margin: 0;
}

.studentApplyHeader p {
  text-align: center;
  margin: 0;

  padding: 0 11%;
}

.studentApplyHeader {
  background-image: url(../../components/assets/backgrounds/apply_header.svg);
  background-size: cover;
  background-position: bottom;
  min-width: 100%;
  min-height: 810px;
}

.studentApplyHeaderContent {
  padding-top: clamp(120px, 18vw, 224px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
}

.studentApplyCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 5%;
  margin-top: -110px;
}

.timeline h2 {
  margin: 0;
  padding: 0px 10%;
}

.timeline h3 {
  margin: 0;
  text-align: center;
}

.timeline p {
  text-align: center;

  margin: 8px 0 0 0;
  padding: 0 0;
  width: 80vw;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-top: 171px;
}

.timelineStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 14px;
}

.timelineStep p {
  text-align: center;
  width: 80vw;
}

.desktopTimelineIcon {
  display: none;
}

.applyButton {
  margin-top: 65px;
  text-align: center;
}

.faq {
  margin-top: 85px;
}

/* Small Screen Styles */
@media screen and (min-width: 800px) {

  .studentApplyCards {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 120px;
    row-gap: 75px;
    justify-content: center;
  }

}

/* Desktop Styles */
@media screen and (min-width: 1200px) {
  .studentApplyHeader {
    background-image: url(../../components/assets/backgrounds/apply_header_desktop.svg);
    min-height: 989px;
  }

  .studentApplyHeader p {
    width: 867px;
    padding: 0;
  }

  .studentApplyCards {
    margin-top: -290px;
  }

  .timeline {
    margin-top: 186px;
    padding: 0 27%;
    gap: 70px;
  }

  .timeline p {
    margin-top: 20px;
    margin: 8px 0 0 0;
    padding: 0 10% 0 0;
    width: 60vw;
  }

  .timeline h2 {
    margin-bottom: -47px;
  }

  .timelineStep {
    display: flex;
    flex-direction: row;
    gap: 80px;
  }

  .mobileTimelineIcon {
    display: none;
  }

  .desktopTimelineIcon {
    display: block;
  }

  .footerDiv {
    margin-top: -20%;
  }

  .faq {
    margin-top: 105px;
  }

  .timeline h3 {
    text-align: left;
  }

  .timelineStep p {
    text-align: left;
    width: 50vw;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@700&family=Rubik:ital@0;1&display=swap');

.Person h2 {
  font-family: 'Karla';
  font-size: 24px;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0;
  text-align: center;
  line-height: 28px;
}

.Person p {
  font-family: 'Rubik';
  font-size: 18px;
  line-height: 21.33px;
  letter-spacing: -0.02em;
  color: #333333;
  white-space: pre-wrap;
  margin: 0;
  text-align: center;
  margin-bottom: 6px;
  max-width: 130px;
}

.Person {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 140px;
  cursor: pointer;
}

.Person img {
  height: 130px;
  width: 130px;
  object-fit: cover;
  border-radius: 50%;
  transition: 0.2s

}

.Person img {
  margin-bottom: 17px;
}

span {
  color: #0069ca;
  text-decoration: none;
}

.Person img:hover {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  transition: 0.2s
}

.Person h2 {
  margin-bottom: 4px;
  max-width: 130px;
}

.team {
  transition: color 300ms ease-out;
}

.team:hover {
  color: #0069ca;
}

.Pronouns {
  font-style: italic;
}


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Lighter transparent background */
  backdrop-filter: blur(4px); /* Background blur */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  animation: fadeIn 0.3s forwards; /* Fade-in transition */
  transition: opacity 0.3s ease-in-out;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}


.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  position: relative;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  background: transparent;
  border: none;
  font-size: 26px;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .Person {
    width: 200px;
  }

  .Person img {
    height: 150px;
    width: 150px;
  }
  .Person h2 {
    max-width: unset;
  }

  .Person p {
    max-width: unset;
  }
}


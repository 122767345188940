@import url('https://fonts.googleapis.com/css?family=Karla');
@import url('https://fonts.googleapis.com/css?family=Rubik');

/* Desktop Styling */ 
.blueBG {
  z-index: -1;
  position: relative;
  left: 0%;
  right: 0%;
  margin-top: -5%;
  width: 99vw;
  height: 100%;
  object-fit: cover;
}

.title {
  display:flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  top: 30%;
  /* Title */

  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  letter-spacing: -0.02em;
  /* H4I National/Black */
  color: #333333;
}

.projectPicture {
  box-sizing: border-box;
  border-radius: 50px;
  justify-content:center;
  align-items: center;
  overflow: hidden;
  display: flex;
  width: 500px;
  height: 333.33px;
  margin: 4px;
  left: 181px;
  top: 451px;
  padding: 25px 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: linear-gradient(116.12deg,#c2e0fb,#cbf9f3);
}

.projectPicture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
  border-radius: 25px;
}


.projectInfoContainer {
  width: 75vw;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  justify-content: center;
}

.flexChild {
  flex: 1;
  align-items: center;
  width: 40%;
  max-width: 560px;
}

.date {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  padding-bottom: 5%;

  /* H4I National/Black */

  color: #333333;
}

.hide {
  display: none;
}

.projectDescription {
  padding: 1em;
  text-align: center;
}

/* Smaller Screen Styling */ 
@media (max-width: 1400px){

  .blueBG {
    width: 100vw;
    height: 110vh;
    object-fit: cover;
  }

  .projectPicture {
    width: 90%;
    height: 250px;
    margin: auto;
    margin-bottom: 3em;
  }

}

@media (max-width:1000px){

  .blueBG{
    width: 100vw;
    height: 100vh auto;
  }

  .projectInfoContainer {
    width: 75vw;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
  }

  .flexChild {
    width: 100%;
  }
}





/* Desktop styles */

.navbarItems {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  z-index: 50;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  gap: 200px;

  backdrop-filter: blur(6px);
  background: linear-gradient(89.4deg, rgba(194, 224, 251, 0.9) 32.23%, rgba(203, 249, 243, 0.9) 53.35%);
}

@media screen and (max-width: 1100px) {
  .navbarItems {
    gap: 100px;
  }
}

.navMenu {
  display: flex;
  list-style: none;
  align-items: center;

  margin-top: 23px;
  margin-right: 10%;
}

.navMenu .navLinks {
  padding: 20px 30px;

  /* identical to box height */
  text-align: center;
  letter-spacing: -0.02em;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #333333; /* H4I National/Black */
  white-space: nowrap;
}

.navLinks:hover {
  color: #657788;

  transition: ease 0.5s;
}

.applyDropdownButton {
  padding: 0px 30px 30px 30px !important;
  margin: 30px 0px 0px 0px !important;
}

.applyDropdownContainer {
  height: 0;
  opacity: 0%;
  visibility: hidden;
  overflow: auto;

  transition: visibility 0s, opacity 0.5s;
}

/* Dropdown Button Styling */
.applyDropdownContainer .applyDropdownButton {
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  border: none;
}

/* Dropdown content (hidden by default) */
.applyDropdownContent {
  position: absolute;
  padding: 0px;
  transform: translate(-15px, 0%);
  top:80px;

  list-style: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: rgba(203, 249, 243, 0.9);
  backdrop-filter: blur(4px);
}

/* Links inside the dropdown */
.applyDropdownContent .navLinks {
  display: block;
  padding: 12px 16px;

  text-decoration: none;
  text-align: center;
  color: #333;
}

.applyDropdownContent .navLinks:hover {
  color: #657788;
  transition: ease 0.5s;
}

/* Show the dropdown menu on hover */
.applyDropdownButton:hover + .applyDropdownContainer,
.applyDropdownContainer:hover {
  visibility: visible;
  z-index: 100;
  opacity: 100%;
}

.navIcon {
  padding: 0.5rem;
  position: absolute;
  z-index: 50;
  display: none;

  height: 40px;
  width: 40px;
  right: 5%;
  margin-left: 0%;
}

.navIcon:hover,
.navIcon:focus {
  cursor: pointer;
}


.logo {
  position: relative; /* Use relative positioning to prevent unexpected movement */
  width: auto; /* Allow width to adjust naturally */
  height: auto; /* Maintain the aspect ratio */
  max-width: 300px; /* Set a maximum width for the logo */
  margin-top: 1.25rem; /* Adjust margins as needed */
  /* margin-left: 2vw; Center the logo more effectively */
}

.faBars {
  color: #0069ca;
}

.blurOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: none;
}

/* Mobile Styles */
@media screen and (max-width: 1000px) {
  .hidden {
    display: none !important;
  }

  /* Graphic styles */
  .logo {
    position: absolute; /* Adjust positioning to fit smaller screens */
    top: -5rem;
    left: 10px; /* Position from the left */
    transform: none; /* Remove transform to prevent unexpected placement */
    width: 300vw; /* Set a responsive width, e.g., 50% of the viewport width */
  }

  .navIcon {
    display: block;
  }

  /* General navbar styles */
  .blurOverlay {
    backdrop-filter: blur(4px);
    background: rgb(255, 255, 255, 0.5);
    display: block;
  }

  .applyDropdownContent {
    transform: translate(15px, 0%);
    top:0px;
  }

  .navMenu {
    display: flex;
    list-style: none;
    align-items: center;
  
    margin-top: 0px;
    margin-right: 0px;
  }

  .navMenu .navLinks {
    list-style: none;
    text-decoration: none;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
  }

  .navbarItems > ul {
    position: fixed;
    top: 70px;

    padding: 20px 0 0 0;
    width: 100vw;
    height: 100vh;

    border-top: 3px solid #0069ca;
    list-style: none;
    text-decoration: none;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;

    transition: ease-in-out opacity 0.7s;
  }
  
  .navMenu {
    display: block;
  }

  .navMenu:target {
    display: block;
  }

  .navbarItems:target + .navIcon {
    display: block;
  }

  .navbarItems li {
    padding-left: 14vw;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }

  .navbarItems li a {
    text-decoration: none;
    font-weight: 700;
    color: #333333;
  }

  /* undoing styles for applyDropdownContainer and respective content */
  .navbarItems .applyDropdownContainer {
    height: auto;
    opacity: 100%;
    display: block;
    visibility: inherit;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .navbarItems .applyDropdownContainer.menuOpen {
    visibility: inherit;
  }

  .applyDropdownContainer .applyDropdownButton {
    display: none;
  }

  .applyDropdownContent {
    background: none;
    width: 100vw;
    transform: none;
    position: initial;
    box-shadow: none;
    border: none;
    height: 100px;
    font-size: 24px;
    font-weight: 700;
    backdrop-filter: none;
    margin-left: 2em;
  }

  .applyDropdownContent li {
    padding-left: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* Links inside the dropdown */
  .applyDropdownContent .navLinks {
    float: none;
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 24px;
  }

  .applyDropdownContent .navLinks:hover {
    color: #657788;
  }
}

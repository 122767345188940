#headerDiv {
  background-image: url(../../components/assets/backgrounds/apply_header.svg);
  background-size: cover;
  background-position: bottom;
  min-width: 100%;
  min-height: 810px;
}

#headerContent {
  padding-top: clamp(120px, 18vw, 224px);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

#title {
  text-align: center;
  margin-bottom: 60px;
  color: #0169ca;
  text-decoration: underline;
  /* padding-left: 15%; */
}
span {
  text-decoration-line: underline;
}

#headerText {
  text-align: start;
  margin: 0;
  width: 70%;
  align-self: center;
  color: black;
}

@media screen and (max-width: 800px) {
  #headerText {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  #headerDiv {
    background-image: url(../../components/assets/backgrounds/apply_header_desktop.svg);
    min-height: 989px;
    margin-bottom: -12rem;
  }

  #headerContent {
    padding-top: clamp(120px, 18vw, 224px);
    display: flex;
    flex-direction: column;
  }
  
  #title {
    /* text-align: center; */
    text-align: start;
    margin-bottom: 60px;
    color: #0169ca;
    text-decoration: underline;
    padding-left: 15%;
  }
  span {
    text-decoration-line: underline;
  }
  
  #headerText {
    text-align: start;
    margin: 0;
    width: 70%;
    padding: 0 15%;
    align-self: start;
    color: black;
  }
  
}
